import {
    createUserWithEmailAndPassword,
    getAuth,
    GoogleAuthProvider,
    onAuthStateChanged,
    signInWithEmailAndPassword,
    signInWithPopup,
    signOut,
    updateProfile,
} from 'firebase/auth';
// import { getAnalytics } from 'firebase/analytics';

import { createContext, useEffect, useState } from 'react';
import app from '../firebase/firebase.config';

export const AuthContext = createContext();
const auth = getAuth(app);
// const analytics = getAnalytics(app);

// eslint-disable-next-line react/prop-types
const UserContext = ({ children }) => {
    const [loading, setLoader] = useState(true);
    const [errorMessage, setErrorMessage] = useState([]);

    const [user, setUser] = useState(null);
    const [value, setValue] = useState('');
    const [students, setStudents] = useState([]);
    const [student, setStudent] = useState([]);

    const createUser = (email, password) => {
        setLoader(true);
        return createUserWithEmailAndPassword(auth, email, password);
    };

    const userNameAndPhoto = (profile) => {
        setLoader(true);
        return updateProfile(auth.currentUser, profile);
    };

    const signIn = (email, password) => {
        setLoader(true);
        return signInWithEmailAndPassword(auth, email, password);
    };

    const googleprovider = new GoogleAuthProvider();

    const googlePopup = () => {
        setLoader(true);
        return signInWithPopup(auth, googleprovider);
    };

    const logOut = () => {
        setLoader(true);
        signOut(auth);
        localStorage.removeItem('authToken');
        localStorage.removeItem('email');
    };

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
            setUser(currentUser);
            setLoader(false);
        });
        return () => unsubscribe();
    }, [user]);

    const authInfo = {
        student,
        setStudent,
        students,
        setStudents,
        value,
        setValue,
        user,
        setUser,
        signIn,
        logOut,
        googlePopup,
        createUser,
        userNameAndPhoto,
        loading,
        setLoader,
        errorMessage,
        setErrorMessage,
    };
    return (
        <AuthContext.Provider value={authInfo}>{children}</AuthContext.Provider>
    );
};

export default UserContext;
