/* eslint-disable react/prop-types */
import { AuthContext } from 'context/UserContext';
import React from 'react';
import { useContext } from 'react';
import { useLocation, useNavigate } from 'react-router';
import Loader from 'ui-component/Loader';

const Public = ({ children }) => {
    const { user, loading } = useContext(AuthContext);
    const location = useLocation();
    const navigate = useNavigate();
    const from = location.state?.from?.pathname || '/dashboard';

    const token = localStorage.getItem('authToken')?.split(' ')[1];

    if (loading) {
        return <Loader />;
    } else if (
        token &&
        (location.pathname === '/login' || location.pathname === '/register')
    ) {
        navigate(from, { replace: true });
    }

    if (!user || !user.uid) {
        return children;
    }

    return children;
};

export default Public;
